import '../styles/globals.scss';
import type {AppProps} from 'next/app';
import Head from 'next/head';
function MyApp({Component, pageProps}: AppProps) {
	return (
		<>
			<Head>
				<title>Padhaai - Free, Quality &amp; Hyperlocal</title>
			</Head>
			<main>
				<Component {...pageProps} />
			</main>
		</>
	);
}

export default MyApp;
